import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create, CreateProps } from 'react-admin';
import { Typography } from '@material-ui/core';

import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { alliances } from './alliances';
import { styles } from './styles';

export const AirlineCreate = (props: CreateProps) => {
  const classes = styles();
  return (
    <Create {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Create New Airline
        </Typography>
        <TextInput source="name" fullWidth />
        <TextInput source="displayName" fullWidth />
        <TextInput
          source="iata"
          label="IATA"
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="icao"
          label="ICAO"
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
        <TextInput
          source="country"
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <SelectInput
          source="alliance.name"
          label="Alliance"
          choices={alliances}
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
      </SimpleForm>
    </Create>
  );
};
