import React from 'react';
import { BooleanInput, SelectInput, SimpleForm, TextInput, Create, CreateProps } from 'react-admin';
import { Typography } from '@material-ui/core';
import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { styles } from './styles';
import { programTypes } from './programTypes';
import { popularity } from './popularity';

export const LoyaltyProgramCreate = (props: CreateProps) => {
  const classes = styles();
  return (
    <Create {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Create New Loyalty Program
        </Typography>
        <TextInput source="displayName" fullWidth />
        <TextInput source="programName" fullWidth />
        <SelectInput
          source="type"
          choices={programTypes}
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <SelectInput
          source="popularity"
          choices={popularity}
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
        <BooleanInput
          source="active"
          className={classes.switch}
          formClassName={classes.inlineBlock}
        />
        <BooleanInput
          source="popular"
          className={classes.switch}
          formClassName={classes.inlineBlockMargin}
        />
        <TextInput source="logoUrl" label="Logo URL" fullWidth />
      </SimpleForm>
    </Create>
  );
};
