import React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  ListProps,
  NumberField,
  TextField,
  TextInput,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import { paginationPropsWithDefaults } from '../../common/defaultPagination';

export const LoyaltyProgramList = (props: ListProps) => (
  <List
    title="Loyalty Programs"
    filters={<LoyaltyProgramFilter />}
    actions={<LoyaltyProgramListActions />}
    sort={{ field: 'displayName', order: 'ASC' }}
    {...props}
    {...paginationPropsWithDefaults}
  >
    <Datagrid rowClick="edit">
      <TextField source="displayName" />
      <TextField source="programName" />
      <TextField source="type" />
      <BooleanField source="active" />
      <BooleanField source="popular" />
      <NumberField source="popularity" />
    </Datagrid>
  </List>
);

const LoyaltyProgramFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const LoyaltyProgramListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton basePath="/loyaltyPrograms" label="New Program" />
  </TopToolbar>
);
