import { cleanEnv, str } from 'envalid';

export const env = cleanEnv(process.env, {
  REACT_APP_FIREBASE_APIKEY: str(),
  REACT_APP_FIREBASE_AUTHDOMAIN: str(),
  REACT_APP_FIREBASE_DATABASEURL: str(),
  REACT_APP_FIREBASE_PROJECTID: str(),
  REACT_APP_FIREBASE_STORAGEBUCKET: str(),
  REACT_APP_FIREBASE_MESSAGINGSENDERID: str(),
  REACT_APP_TREKLEE_API_URL: str(),
});
