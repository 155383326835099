import { FirebaseAuthProvider } from 'react-admin-firebase';
import debounce from 'lodash/debounce';
import { User } from '@firebase/auth-types';
import { env } from '../env';
import { clearToken, getToken, setToken } from '../utils/localStorage';

export const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  authDomain: env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_STORAGEBUCKET,
};

const options = {
  logging: false,
};

/**
 * @typedef {Object} User provided by Firebase
 */

/**
 * @typedef {Object} Permissions custom claims for the logged in user
 * @property {number} Permissions.exp token expiration (seconds)
 * @property {string[]} [Permissions.scope] example: ["USER", "ADMIN"]
 *
 * @example const permissions = await authProvider.getPermissions();
 */

const authProvider = FirebaseAuthProvider(firebaseConfig, options);

/**
 * @param {Permissions['exp']} expirationInSeconds
 */
const getRemainingTokenTimeInMinutes = (expirationInSeconds: number) => {
  const expirationMilliseconds = expirationInSeconds * 1000;
  const timeRemainingMilliseconds =
    new Date(expirationMilliseconds).getTime() - new Date(Date.now()).getTime();
  return timeRemainingMilliseconds / 1000 / 60;
};

/**
 * Allow firebase to refresh token if near expiration
 * @param {User} user
 * @param {Permissions['exp']} expirationInSeconds
 */
const refreshTokenIfExpired = async (user: User, expirationInSeconds: number) => {
  const minutesUntilTokenExpiration = getRemainingTokenTimeInMinutes(expirationInSeconds);

  if (minutesUntilTokenExpiration < 50) {
    const newToken = await user.getIdToken(/* force refresh */ true);
    setToken(newToken);
  }
};

/**
 * @throws {Error} if user is not admin status
 * @param {Permissions['scope']} permissionsScope
 */
const allowAdminOnly = async (permissionsScope: string[]) => {
  const isAdmin = Array.isArray(permissionsScope) && permissionsScope.includes('ADMIN');

  if (!isAdmin) {
    // Make sure user is logged out, if not an admin
    await authProvider.logout({});
    throw new Error('Login error, invalid permissions');
  }
};

/** This fires on route changes and possibly more. */
const checkAuthHandler = async () => {
  const user = await authProvider.checkAuth({});
  const { exp, scope } = await authProvider.getPermissions({});

  // do admin check
  await allowAdminOnly(scope);

  // set token if none present
  if (!getToken() || user === null) {
    const newToken = await authProvider.getJWTToken();
    setToken(newToken);
    return;
  }

  // Automatically refresh token if near expiration
  // Debounce: check the token at most once every 30 seconds
  await debounce(refreshTokenIfExpired, /* wait */ 1000 * 30, {
    leading: true,
    trailing: false,
    // @ts-expect-error user may not be void
  })(user, exp);

  return;
};

/** This seems to only fire for email/password login */
const loginHandler = async (params: any) => {
  await authProvider.login(params); // returns user
  const { scope } = await authProvider.getPermissions({});

  // do admin check
  await allowAdminOnly(scope);

  // set initial token
  const token = await authProvider.getJWTToken();
  setToken(token);
};

const logoutHandler = async (params: any) => {
  await authProvider.logout(params);
  clearToken();
};

/*
https://github.com/benwinding/react-admin-firebase/issues/178
The way permissions is set up is as an Array of claims so try using
  `permissions.includes('admin')`

Restricting Login
As discussed in #140, you can wrap the authProvider, so that it requires
 that the user has the 'admin' claim set (as shown below).
*/
export default {
  // Copy all authProvider functionality
  ...authProvider,

  // Wrap providers in order to check for custom claims, etc:
  checkAuth: checkAuthHandler,
  login: loginHandler,
  logout: logoutHandler,
};
