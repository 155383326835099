import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles({
  active: { width: '8em' },
  id: { width: '15em' },
  vendorId: { width: '6em' },
  program: { width: '29em' },
  activeFormGroup: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    marginTop: '1em',
  },
  inlineBlock: { display: 'inline-block' },
  inlineBlockMargin: { display: 'inline-block', marginLeft: 32 },
  card: {
    maxWidth: '40em',
  },
});
