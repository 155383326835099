import React from 'react';
import { Edit, EditProps, SimpleForm, TextInput, SelectInput } from 'react-admin';
import Image from 'material-ui-image';
import { Typography } from '@material-ui/core';

import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { alliances } from './alliances';
import { styles } from './styles';

export const AirlineEdit = (props: EditProps) => {
  const classes = styles();
  return (
    <Edit {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Edit Airline
        </Typography>
        <Image
          src={`https://static.treklee.com/public/images/airlines/${props.id}.png`}
          imageStyle={{ width: '70px', height: '70px' }}
          style={{ 'padding-top': '80px' }}
        />
        <TextInput source="name" fullWidth />
        <TextInput source="displayName" fullWidth />
        <TextInput
          source="iata"
          label="IATA"
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="icao"
          label="ICAO"
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
        <TextInput
          source="country"
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <SelectInput
          source="alliance.name"
          label="Alliance"
          choices={alliances}
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
      </SimpleForm>
    </Edit>
  );
};
