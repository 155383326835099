import React from 'react';
import {
  Datagrid,
  List,
  ListProps,
  TextField,
  TextInput,
  Filter,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import { paginationPropsWithDefaults } from '../../common/defaultPagination';

export const AirlineList = (props: ListProps) => {
  return (
    <List
      {...props}
      title="Airlines"
      filters={<AirlineFilter />}
      actions={<AirlineListActions />}
      sort={{ field: 'name', order: 'ASC' }}
      {...paginationPropsWithDefaults}
    >
      <Datagrid rowClick="edit">
        <TextField source="iata" label="IATA" />
        <TextField source="name" />
        <TextField source="displayName" />
        <TextField source="country" />
        <TextField source="icao" label="ICAO" />
        <TextField source="alliance.name" label="Alliance" />
      </Datagrid>
    </List>
  );
};

const AirlineFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const AirlineListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton basePath="/airlines" label="New Airline" />
  </TopToolbar>
);
