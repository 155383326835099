import { LoyaltyProgramList } from './LoyaltyProgramList';
import { LoyaltyProgramCreate } from './LoyaltyProgramCreate';
import { LoyaltyProgramEdit } from './LoyaltyProgramEdit';
import { CardMembership } from '@material-ui/icons';

export default {
  list: LoyaltyProgramList,
  create: LoyaltyProgramCreate,
  edit: LoyaltyProgramEdit,
  icon: CardMembership,
  options: { label: 'Loyalty Programs' },
};
