import React from 'react';
import { Pagination } from 'react-admin';

const perPage = 20;
const rowsPerPageOptions = [10, 20, 50];

export const paginationPropsWithDefaults = {
  perPage,
  pagination: <Pagination rowsPerPageOptions={rowsPerPageOptions} />,
};
