import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const Dashboard: React.FC = () => (
  <Card>
    <CardHeader title="Treklee Admin Panel" />
    <CardContent>Welcome!</CardContent>
  </Card>
);

export default Dashboard;
