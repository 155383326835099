import React from 'react';
import { Admin, Resource } from 'react-admin';

import authProvider from '../providers/authProvider';
import dataProvider from '../providers/dataProvider';

import Dashboard from './Dashboard';

import airlines from './resources/airlines';
import loyaltyPrograms from './resources/loyaltyPrograms';
import milezPrograms from './resources/milezPrograms';
import airports from './resources/airports';

import CustomLoginPage from './user/CustomLoginPage';

import { trekleeTheme as theme } from '../themes';

const App: React.FC = () => (
  <Admin
    authProvider={authProvider}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    loginPage={CustomLoginPage}
    theme={theme}
  >
    {/* Example */}
    {/*
    <Resource name="comments" list={ListGuesser} />
    <Resource name="posts" list={ListGuesser} />
    <Resource name="users" list={ListGuesser} />
     */}

    {/* Treklee Resources */}
    <Resource name="airlines" {...airlines} />
    <Resource name="loyaltyPrograms" {...loyaltyPrograms} />
    <Resource name="milezPrograms" {...milezPrograms} />
    <Resource name="airports" {...airports} />
  </Admin>
);

export default App;
