import React from 'react';
import { Login, LoginForm } from 'react-admin';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';

import ForgotPasswordButton from './CustomForgotPassword';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // Display auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

const CustomLoginPage: React.FC = (props) => (
  <Login {...props}>
    {/* "Sign in with" */}
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

    <LoginForm {...props} />
    <ForgotPasswordButton {...props} />
  </Login>
);

export default CustomLoginPage;
