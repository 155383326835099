import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  Filter,
  List,
  ListProps,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  SearchInput,
  TopToolbar,
  CreateButton,
} from 'react-admin';
import { paginationPropsWithDefaults } from '../../common/defaultPagination';

export const MilezProgramList = (props: ListProps) => (
  <List
    {...props}
    title="Milez.biz Programs"
    sort={{ field: 'vendorId', order: 'ASC' }}
    filters={<MilezProgramFilter />}
    actions={<MilezProgramListActions />}
    {...paginationPropsWithDefaults}
  >
    <Datagrid rowClick="edit">
      <TextField source="vendorId" label="Vendor ID" />
      <TextField source="name" />
      <BooleanField source="active" />
      <ReferenceField source="programId" reference="loyaltyPrograms" label="Loyalty Program">
        <TextField source="displayName" />
      </ReferenceField>
      <ReferenceArrayField label="Airlines" reference="airlines" source="airlineIatas">
        <SingleFieldList>
          <ChipField source="displayName" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

const MilezProgramFilter: React.FC = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const MilezProgramListActions: React.FC = () => {
  return (
    <TopToolbar>
      <CreateButton basePath="/milezPrograms" label="New Program" />
    </TopToolbar>
  );
};
