import React from 'react';
import {
  BooleanInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Create,
  CreateProps,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { styles } from './styles';

export const MilezProgramCreate = (props: CreateProps) => {
  const classes = styles();
  return (
    <Create {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Create New Milez.biz Program
        </Typography>
        <TextInput source="name" fullWidth />
        <BooleanInput
          source="active"
          className={classes.active}
          formClassName={classes.activeFormGroup}
        />
        <TextInput
          source="vendorId"
          label="Vendor ID"
          className={classes.vendorId}
          formClassName={classes.inlineBlockMargin}
        />
        <ReferenceInput
          source="programId"
          reference="loyaltyPrograms"
          label="Treklee Loyalty Program"
          fullWidth
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Airlines"
          source="airlineIatas"
          reference="airlines"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          fullWidth
        >
          <SelectArrayInput optionValue="iata" optionText="displayName" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
