import React from 'react';
import { Box, CardContent } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
}

export const DefaultEditLayout: React.FC = ({ children }: Props) => {
  return (
    <CardContent>
      <Box display="flex">
        <Box ml={2} flex="1" maxWidth="31em">
          {children}
        </Box>
      </Box>
    </CardContent>
  );
};
