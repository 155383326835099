import { AirlineList } from './AirlineList';
import { AirlineEdit } from './AirlineEdit';
import { AirlineCreate } from './AirlineCreate';
import { FlightTakeoff } from '@material-ui/icons';

export default {
  list: AirlineList,
  create: AirlineCreate,
  edit: AirlineEdit,
  icon: FlightTakeoff,
};
