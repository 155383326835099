import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { styles } from './styles';

export const MilezProgramEdit = (props: EditProps) => {
  const classes = styles();
  return (
    <Edit {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Edit Milez.biz Program
        </Typography>
        <TextInput source="name" fullWidth />
        <BooleanInput
          source="active"
          className={classes.active}
          formClassName={classes.activeFormGroup}
        />
        <TextInput
          source="id"
          label="ID"
          className={classes.id}
          formClassName={classes.inlineBlock}
          disabled
        />
        <TextInput
          source="vendorId"
          label="Vendor ID"
          className={classes.vendorId}
          formClassName={classes.inlineBlockMargin}
        />
        <ReferenceInput
          source="programId"
          reference="loyaltyPrograms"
          sort={{ field: 'displayName', order: 'ASC' }}
          perPage={100}
          label="Treklee Loyalty Program"
          fullWidth
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Airlines"
          source="airlineIatas"
          reference="airlines"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
          fullWidth
        >
          <SelectArrayInput optionValue="iata" optionText="displayName" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
