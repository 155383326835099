import { createMuiTheme } from '@material-ui/core/styles';

export const trekleeTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0389ff', // buttons/links 
    },
    secondary: {
      light: '#FF6347', // red temporarily
      main: '#004bff', // nav bar color
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Open Sans',
      'sans-serif',
    ].join(','),
    // useNextVariants: true,
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
  },
  typography: {
    // useNextVariants: true,
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    // useNextVariants: true,
  },
});
