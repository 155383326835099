import { MilezProgramList } from './MilezProgramList';
import { MilezProgramCreate } from './MilezProgramCreate';
import { MilezProgramEdit } from './MilezProgramEdit';
import { CardTravel } from '@material-ui/icons';

export default {
  list: MilezProgramList,
  create: MilezProgramCreate,
  edit: MilezProgramEdit,
  icon: CardTravel,
  options: { label: 'Milez.biz Programs' },
};
