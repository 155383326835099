import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  ImageField,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { DefaultEditLayout } from '../../common/DefaultEditLayout';
import { styles } from './styles';
import { programTypes } from './programTypes';
import { popularity } from './popularity';

export const LoyaltyProgramEdit = (props: EditProps) => {
  const classes = styles();
  return (
    <Edit {...props} actions={false}>
      <SimpleForm component={DefaultEditLayout} redirect="list">
        <Typography variant="h5" gutterBottom>
          Edit Loyalty Program
        </Typography>
        <ImageField source="logoUrl" label="" />
        <TextInput source="displayName" fullWidth />
        <TextInput
          source="programName"
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="id"
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
          disabled
        />
        <SelectInput
          source="type"
          choices={programTypes}
          className={classes.halfWidth}
          formClassName={classes.inlineBlock}
        />
        <SelectInput
          source="popularity"
          choices={popularity}
          className={classes.halfWidth}
          formClassName={classes.inlineBlockMargin}
        />
        <BooleanInput
          source="active"
          className={classes.switch}
          formClassName={classes.inlineBlock}
        />
        <BooleanInput
          source="popular"
          className={classes.switch}
          formClassName={classes.inlineBlockMargin}
        />
        <TextInput source="logoUrl" label="Logo URL" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
