import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { env } from '../env';
import { getToken } from '../utils/localStorage';

const apiUrl = env.REACT_APP_TREKLEE_API_URL;

const getServicePrefix = (resource) => {
  const FLIGHT_RESOURCES = ['airlines', 'loyaltyPrograms', 'milezPrograms'];
  const AIRPORT_RESOURCES = ['airports'];

  if (FLIGHT_RESOURCES.includes(resource)) {
    return 'flights';
  } else if (AIRPORT_RESOURCES.includes(resource)) {
    return 'airports';
  }

  throw new Error(`Unknown resource type: ${resource}`);
};

const createQuery = (params) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  let query = '';

  if (params.filter && params.filter.q) {
    query = params.filter.q;
  }

  return `page=${page - 1}&size=${perPage}&sort=${field},${order}&q=${query}`;
};

const createUrl = (resource) => {
  return `${apiUrl}/${getServicePrefix(resource)}/admin/${resource}`;
};

const extractTotal = (headers) => {
  const rangeHeader = headers.get('X-Total-Count');
  if (rangeHeader) {
    return parseInt(rangeHeader.split('/').pop(), 10);
  }

  throw new Error('X-Total-Count header is missing in the HTTP Response!');
};

interface FetchOptions {
  headers?: Headers;
  user?: any;
  body?: any;
  method?: string;
}

// inject authenticated token into all requests
const wrappedFetchJson = (url, options: FetchOptions = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // Add token to all API requests
  const token = getToken();
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }

  return fetchUtils.fetchJson(url, options);
};

const dataProviderBase = (httpClient = wrappedFetchJson) => {
  return {
    getList: (resource, params) => {
      const url = `${createUrl(resource)}?${createQuery(params)}`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json,
        total: extractTotal(headers),
      }));
    },

    getOne: (resource, params) => {
      return httpClient(`${createUrl(resource)}/${params.id}`).then(({ json }) => ({
        data: json,
      }));
    },

    getMany: (resource, params) => {
      const url = `${createUrl(resource)}/batch?ids=${params.ids}`;

      return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (/* resource, params */) => {
      throw new Error('getManyReference not yet supported!');
    },

    update: (resource, params) => {
      return httpClient(`${createUrl(resource)}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };

      return httpClient(`${createUrl(resource)}?${stringify(query)}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
      return httpClient(`${createUrl(resource)}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    },

    delete: (resource, params) => {
      return httpClient(`${createUrl(resource)}/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }));
    },

    deleteMany: (resource, params) => {
      const url = `${createUrl(resource)}/batch?ids=${params.ids}`;

      return httpClient(url, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }));
    },
  };
};

export default dataProviderBase(wrappedFetchJson);
