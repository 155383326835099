import React from 'react';
import { List, ListProps, Datagrid, TextField, BooleanField, TextInput, Filter } from 'react-admin';
import { paginationPropsWithDefaults } from '../../common/defaultPagination';

export const AirportList = (props: ListProps) => {
  return (
    <List
      sort={{ field: 'displayName', order: 'ASC' }}
      filters={<AirportFilter />}
      actions={false}
      {...props}
      {...paginationPropsWithDefaults}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="IATA" />
        <TextField source="name" />
        <TextField source="displayName" />
        <TextField source="shortDisplayName" />
        <TextField source="city" />
        <TextField source="stateCode" />
        <TextField source="country" />
        <BooleanField source="multiAirport" label="Multi Airport" />
        <BooleanField source="childAirport" label="Child Airport" />
      </Datagrid>
    </List>
  );
};

const AirportFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
